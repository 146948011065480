<template>
  <div>
      <div class="glo-nav">数据走势</div>
      <div class="nav-char">
        <div v-for="(item,index) in navlist" :key="index" class="wid_3">
            <div :class="{'block': true,'active': active == index ? true : false}" @click="selectChart(index)">{{ item }}</div>
        </div>
      </div>
      <div :class="'glo-content ' +(noticeShow ? 'glo-magbot' : 'glo-magbot0')">
        <div>
          <div v-if="loading">
              <van-loading type="spinner" vertical>加载中</van-loading>
          </div>
          <div v-else>
              <div v-if="active == 0"><canvas id="xaxisangleChart"></canvas></div>
              <div v-else-if="active == 1"><canvas id="vibampChart"></canvas></div>
              <div v-else><canvas id="averageChart"></canvas></div>
          </div>
        </div>
        <tabbar />
      </div>
  </div>
</template>

<script>
const F2 = require('@antv/f2');
import tabbar from '@/components/bridge/NFtabbar.vue'
export default {
  components:{
      tabbar
  },
  props: ['magbot'],
  data(){
    return{
        active:0,
        vibampArr:[],
        xaxisangleArr:[],
        accAverageArr:[],
        bridgeCode:'',
        loading:true,
        navlist:['倾角','挠度','加速度']
    }
  },
  watch: {
      magbot: function (val) {   
        this.magbotVal = val;
      }
  },
  methods:{
    // 点击切换绘制
    selectChart(index){
      this.active = index;
      if(this.active == 0){
        this.xaxisangleShow();
      }else if(this.active == 1){
        this.vibampShow();
      }else if(this.active == 2){
        this.averageShow();
      }
    },
    // 数据格式化
    getChartInfo(){
       this.$api.NEWBRIDGE.sensorTrend({
         projectCode:this.projectCode
       }).then( d =>{
          var xaxisangleArr = []; // 地表倾斜
          var vibampArr = []; // 地表位移
          var accAverageArr = [];
          for(let i=0;i<d.length;i++){
              d[i].date.forEach((e,index)=>{
                  vibampArr.push({
                    date:e.substring(0,e.lastIndexOf(':')),
                    vibamp:parseFloat(d[i].vibamp[index]),
                    name:d[i].name
                  });
                  accAverageArr.push({
                    date:e.substring(0,e.lastIndexOf(':')),
                    accAverage:parseFloat(d[i].accMaximum[index]),
                    name:d[i].name
                  });
              })

              d[i].xaxisangleDate.forEach((e,index)=>{
                    xaxisangleArr.push({
                      date:e.substring(0,e.lastIndexOf(':')),
                      xaxisangle:parseFloat(d[i].xaxisangle[index]),
                      name:d[i].name
                    });
                })
          }
          this.xaxisangleArr = xaxisangleArr;
          this.vibampArr = vibampArr;
          this.accAverageArr = accAverageArr;
          this.loading = false;
          this.xaxisangleShow();
       })
    },
    // 绘制表面倾斜图表
    xaxisangleShow(){
      let that = this;
      this.$nextTick(()=>{
        const chart = new F2.Chart({
          id: 'xaxisangleChart',
          pixelRatio: window.devicePixelRatio
        });
        chart.source(this.xaxisangleArr);
        chart.scale('date', {
          type: 'timeCat',
          mask: "MM-DD HH:mm",
          tickCount: 4,
          range:[0,1]
        });
        chart.scale('xaxisangle', {
          tickCount: 6
        });
        chart.axis('date', {
          label: function label(text, index, total) {
            const textCfg = {};
            if (index === 0) {
              textCfg.textAlign = 'left';
            } else if (index === total - 1) {
              textCfg.textAlign = 'right';
            }
            return textCfg;
          }
        });
        chart.tooltip({
          custom: true, // 自定义 tooltip 内容框
          showXTip: true, // 是否展示 X 轴的辅助信息
          xTip: (obj)=>{
            return obj
          },
          onChange: function onChange(obj) {
            that.active != 0 ? chart.tooltip(false) : void 0 ;
            const legend = chart.get('legendController').legends.top[0];
            const tooltipItems = obj.items;
            const legendItems = legend.items;
            const map = {};
            legendItems.forEach(function(item) {
              map[item.name] = item;
            });
            tooltipItems.forEach(function(item) {
              const name = item.name;
              const value = item.value;
              if (map[name]) {
                map[name].value = value;
              }
            });
            legend.setItems(Object.values(map));
          }
        });
        chart.line({
          connectNulls: true // 将空数据连接
        }).shape('smooth').position('date*xaxisangle').color('name');
        chart.render();
      })
    },
    // 绘制地表位移图表
    vibampShow(){
      let that = this;
      this.$nextTick( () => {
          const chart = new F2.Chart({
            id: 'vibampChart',
            pixelRatio: window.devicePixelRatio
          });
          chart.source(this.vibampArr);
          chart.scale('date', {
            type: 'timeCat',
            mask: "MM-DD HH:mm",
            tickCount: 4,
            range:[0,1]
          });
          chart.scale('vibamp', {
            tickCount: 5
          });
          chart.axis('date', {
            label: function label(text, index, total) {
              const textCfg = {};
              if (index === 0) {
                textCfg.textAlign = 'left';
              } else if (index === total - 1) {
                textCfg.textAlign = 'right';
              }
              return textCfg;
            }
          });
          chart.tooltip({
            custom: true, // 自定义 tooltip 内容框
            showXTip: true, // 是否展示 X 轴的辅助信息
              xTip: (obj)=>{
                return obj
            },
            onChange: function onChange(obj) {
              that.active != 1 ? chart.tooltip(false) : void 0 ;
              const legend = chart.get('legendController').legends.top[0];
              const tooltipItems = obj.items;
              const legendItems = legend.items;
              const map = {};
              legendItems.forEach(function(item) {
                map[item.name] = item;
              });
              tooltipItems.forEach(function(item) {
                const name = item.name;
                const value = item.value;
                if (map[name]) {
                  map[name].value = value;
                }
              });
              legend.setItems(Object.values(map));
            }
          });
          chart.line().position('date*vibamp').color('name');
          chart.render();
      })
    },
    // 绘制平均速度曲线
    averageShow(){
      let that = this;
      this.$nextTick(()=>{
        const chart = new F2.Chart({
          id: 'averageChart',
          pixelRatio: window.devicePixelRatio
        });
        chart.source(this.accAverageArr);
        chart.scale('date', {
          type: 'timeCat',
          mask: "MM-DD HH:mm",
          tickCount: 4,
          range:[0,1]
        });
        chart.scale('accAverage', {
          tickCount: 6
        });
        chart.axis('date', {
          label: function label(text, index, total) {
            const textCfg = {};
            if (index === 0) {
              textCfg.textAlign = 'left';
            } else if (index === total - 1) {
              textCfg.textAlign = 'right';
            }
            return textCfg;
          }
        });
        chart.tooltip({
          custom: true, // 自定义 tooltip 内容框
          showXTip: true, // 是否展示 X 轴的辅助信息
          xTip: (obj)=>{
            return obj
          },
          onChange: function onChange(obj) {
            that.active != 2 ? chart.tooltip(false) : void 0 ;
            const legend = chart.get('legendController').legends.top[0];
            const tooltipItems = obj.items;
            const legendItems = legend.items;
            const map = {};
            legendItems.forEach(function(item) {
              map[item.name] = item;
            });
            tooltipItems.forEach(function(item) {
              const name = item.name;
              const value = item.value;
              if (map[name]) {
                map[name].value = value;
              }
            });
            legend.setItems(Object.values(map));
          }
        });
        chart.line({
          connectNulls: true // 将空数据连接
        }).shape('smooth').position('date*accAverage').color('name');
        chart.render();
      })
    }
  },
  computed:{
    noticeShow(){
      return window.sessionStorage.getItem('magbot') == '1' && this.magbot != 1 ? true : false
    }
  },
  mounted(){
      this.projectCode = window.sessionStorage.getItem('projectCode');
      this.projectCode ? this.getChartInfo() : void 0;
  }
}
</script>

<style lang="scss" scoped>
.nav-char{
  padding: 4%;
  display: flex;
  justify-content: space-around;
  .wid_3{width: 30%;}
  .block{
    line-height: 3;
    padding: 2% 4%;
    border-radius: 10px;
    color: #585858;
    box-shadow: 1px 1px 5px #dadada;
    font-weight: bold;
    margin-right: 2%;
  }
  .active{
    background:linear-gradient(#40b5d8 0%, #6ed4c6 85%);
    color: whitesmoke;
  }
}
#xaxisangleChart{width: 100%;height: 26rem;}
#vibampChart{width: 100%;height: 26rem;}
#averageChart{width: 100%;height: 26rem;}
.title{
  text-align: left;font-size: .95rem;line-height:2;padding-left: 4%;
  .fa{color: #1989fa;font-size: 1.2rem;padding-right: 2%;}
}
</style>